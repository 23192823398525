body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #232323;
  color: #F4F4F4;
  background-image: url("../assets/texture.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

h2 {
  font-size: 24px;
}

.container {
  margin: auto;
}

body .show {
  display: flex;
    transition: all 1s;
    opacity: 1;
}

body .opacity {
  opacity: 1;
  transition: all 2s;
}

body .show .container {
  opacity: 1;
  transition: all 1s;
}

.overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #7f7f7f;
  mix-blend-mode: color;
  transition: all 1s;
  clip-path: polygon(0 0, calc(100% - 40px) 0, 100% 40px, 100% 100%, 40px 100%, 0 calc(100% - 40px));
}

.overlay:hover:after {
  opacity: 0;
}

.clippatch {
  clip-path: polygon(0 0, calc(100% - 40px) 0, 100% 40px, 100% 100%, 40px 100%, 0 calc(100% - 40px)); 
}

div.swiper-button-prev{
  position: absolute;
  left: 0;
}

div.swiper-button-next{
  position: absolute;
  right: 0px;
}

div.swiper-button-prev,
div.swiper-button-next {
  top: 30px !important;
  width: 23px !important;
  color: #fff;
}
div.swiper-button-prev::after,
div.swiper-button-next::after {
  font-size: 30px!important;
}

div.swiper-button-prev,
div.swiper-button-next {
  display: none;
}

#portfolio div.swiper-button-prev,
#portfolio div.swiper-button-next {
  display: initial;
}

.banner  .swiper-pagination-bullet {
  background-color: #fff;
  border: solid 2px rgba(0,0,0,0);
}

body .banner .swiper-pagination-bullet-active {
  background: rgba(255,255,255,0.3);
  border: solid 2px #fff
}

body .react-images__footer{
  justify-content: center;
}

button .css-1h82jk3{
  top: 106%;
}

button .css-xfk84m {
  top: 105%;
}
/*arrow rigth*/
.fullscreen .css-xfk84m, .fullscreen .css-xfk84m:hover{
  background: rgba(0,0,0,0);
}
/*arrow left*/
.fullscreen .css-1h82jk3, .fullscreen .css-1h82jk3:hover{
  background: rgba(0,0,0,0);
}
