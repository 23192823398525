.slidedown {
  animation-duration: 3s;
  animation-name: slidedown;
}

@keyframes slidedown {
  from {
    margin-top: -30px;
    padding-bottom: 30px;
    opacity: 0;
  }

  to {
    margin-top: 0px;
    padding-bottom: 00px; 
    opacity: 1;
  }
}

.alphaeffect {
  animation-duration: 3s;
  animation-name: alphaeffect;
}

@keyframes alphaeffect {
  0% { opacity: 0; }
  33% { opacity: 0; }
  100% { opacity: 1; }
}

.alphaeffectFast {
  animation-duration: 3s;
  animation-name: alphaeffect;
}

@keyframes alphaeffect {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

#sending {
  position: fixed;
  top: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  opacity: 0.7;
}

#sending img {
  width: 50px;
}