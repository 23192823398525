@media only screen and (min-width: 1701px)  {
  .container {
    width: 1600px;
  }
}
@media only screen and (max-width: 1700px)  {
  .container {
    width: 1150px;
  }
}
@media only screen and (max-width: 1200px)  {
  .container {
    width: 980px;
  }
}
@media only screen and (max-width: 998px)  {
  .container {
    width: 900px;
  }
}
@media only screen and (max-width: 778px)  {
  .container {
    width: 600px;
  }
}
@media only screen and (max-width: 600px)  {
  .container {
    width: 500px;
  }
}
@media only screen and (max-width: 480px)  {
  .container {
    width: 85%;
  }
}
